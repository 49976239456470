body {
  margin: 0;
  padding: 0;
}

.text-green {
  color: green;
}

.text-red {
  color: red;
}
